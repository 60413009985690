@import './bottomPanel';

#systemContainer {
	display: none;
}

.mainSystem {
	padding-top: 80px;
	padding-bottom: 240px;
}

.col-2 {
	padding-top: 10px;
}

.sectionsMenu div:hover {
	cursor: pointer;
}

.mainSystem .appContent {
	display: flex;
	
}



.projectPageAvailableIDs {
	margin-top: 100px;
	font-size: 16px;
	line-height: 34px;
}

.projectPageAvailableIDs .link {
	display: block;
}







.mainSystem .button {
	height: 34px;
}


.mainSystem .ant-input-number-input {
	text-align: center;
	font-family: $font;
}



.mainSystem .sections,
.mainSystem .fields,
.mainSystem .fields .leftSide,
.mainSystem .field .inner {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	font-family: $fontSemiBold;
}

.mainSystem .fields {
	align-items: flex-start;
}

.mainSystem .fields .leftSide {
	align-items: center;
}

.mainSystem .fields .leftSide .title {
	font-size: 16px;
	margin-bottom: 0;
}

.mainSystem .leftSide .title.hasInfo {
	border-bottom: 2px dashed #ccc;
}

.mainSystem .leftSide .title.hasInfo:hover {
	border-bottom-color: transparent;
}








.mainSystem .mainTitle {
    margin: 0 0 30px 10px;
    font-size: 21px;
    font-family: "Inter Semibold", sans-serif;
    text-decoration: none;
	font-family: $fontSemiBold;
	white-space: break-spaces;
	line-height: 28px;
}

.mainSystem .mainTitle .id {
	position: relative;
	height: 27px;
	margin-top: 5px;
	font-size: 16px;
	display: inline-block;
	cursor: pointer;
	border-bottom: 1px solid #a5a5a5;
}

.mainSystem .mainTitle .id:hover {
	border-color: rgba(0,0,0,0);
}

.mainSystem .mainTitle .id .feather {
    position: absolute;
	top: 3px;
    right: -28px;
    width: 28px;
    height: 23px;
    opacity: 0.4;
}

.mainSystem .section {
	position: relative;
	// width: 610px;
	width: 100%;
	margin-bottom: -30px;
	padding-top: 70px;
}

.mainSystem .section > .content {
	border: 1px solid #ddd;
	border-radius: 7px;
	padding: 20px 15px;
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}










.mainSystem .section .subgroup {
	position: relative;
    margin-top: 20px;
}

#section_missions .subgroup .field {
	width: auto;
}

#section_missions .subgroup {
	display: flex;
	flex-direction: column;
	margin-top: 0;
	margin-bottom: 20px;
}

#section_missions .header {
	margin-bottom: 20px;
}

.mainSystem .subgroup > .title {
	border-bottom: 1px solid #ccc;
    padding-bottom: 4px;
    font-size: 15px;
	margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 12px;
	color: #0056db;
	max-width: 90%;
}











.mainSystem .pageTitle {
	font-size: 24px;
	font-family: $fontSemiBold;
}









.section .header .top {
	display: flex;
	align-items: center;
	margin-left: 12px;
	margin-bottom: 15px;
}

.section .header .top .name {
	font-size: 17px;
	opacity: .5;
	font-family: $font;
	margin-left: 15px;
}

.section .header .editBtn {
	margin-left: 12px;
}

.mainSystem .section>.title {
	margin-left: 20px;
	margin-bottom: 30px;
	font-size: 26px;
	font-family: $fontBold;
}

.mainSystem .header .titleExtended {
	font-size: 21px;
	color: #42669d;
	font-family: $fontSemiBold;
}




.mainSystem .field {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	width: 610px;
	margin-right: 70px;
	font-size: 17px;
}

.mainSystem .sectionsMenu {
	max-height: 530px;
}

.mainSystem .sectionsMenu .item {
	margin-bottom: 10px;
	border-radius: 7px;
	text-decoration: none;
	padding: 5px 10px;
	margin-right: 8px;
}

.mainSystem .sectionsMenu .item:hover {
	background-color: #2072f2;
	color: #fff;
}


.mainSystem .header {
	position: relative;
	display: inline-flex;
	align-items: flex-start;
	margin: 3px 0;
	margin-bottom: 10px;
	font-size: 17px;
	flex-direction: column;
}

.mainSystem .field .inner {
	width: 100%;
	padding: 5px 12px;
	border-radius: 7px;
	align-items: center;
	justify-content: space-between;
	flex-wrap: nowrap;
	flex-shrink: 0;
}
z
.mainSystem .field .inner.wrap {
	flex-wrap: wrap;
}

.mainSystem .field:hover .inner {
	background-color: rgb(220 231 255 / 37%);
}



.mainSystem .fields .name {
	max-width: 250px;
	margin-left: 10px;
	font-size: 14px;
	opacity: 0.6;
	font-family: $font;
}

.mainSystem .fields .noData {
	font-size: 15px;
    margin-left: 12px;
    margin-top: 10px;
	font-family: $font;
}












// ref field
.mainSystem .refField {
	border: 1px solid #6668cc;
	border-radius: 7px;
}

.mainSystem .refField .refInfo {
	position: absolute;
	left: 9px;
    top: -12px;
	width: 32px;
	height: 24px;
	background-color: #fff;
}

.mainSystem .refField .feather {
	position: absolute;
	left: 4px;
	top: 0;
}


.mainSystem .refField .linkedFields {
	font-family: $fontSemiBold;
}



























.field .listView {
	display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-basis: 100%;
    margin-top: 12px;
}

.field .listView .list {
	padding: 6px 11px;
    border: 1px solid #ccc;
    border-radius: 7px;
    max-height: 119px;
	max-width: 182px;
    overflow: auto;
	background-color: #fff;
}

.field .listView .inputBox {
    margin: 0 20px;
    width: 40%;
}

.mainSystem .ant-input-number {
	font-size: 15px;
}

.mainSystem .field .listView .input {
    max-width: 100%;
	border-color: #a8a8a8;
	font-family: $font;
	font-size: 17px;
}

.mainSystem .field .listView .addBtn {
    margin: 0;
    margin-top: 12px;
}

.field .listView .item {
	position: relative;
	padding-right: 21px;
	margin: 5px;
	font-size: 17px;
	font-family: $font;
}

.field .listView .content {
	position: relative;
	display: inline-block;
}

.field .listView .removeBtn {
	position: absolute;
	right: -22px;
	top: -5px;
	transform: rotate(45deg);
	font-size: 23px;
	opacity: 0;
	cursor: pointer;
}

.field .listView .item:hover .removeBtn {
	opacity: .5;
}











// date input
.field_datetime .dateInput,
.field_date .dateInput {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 170px;
	height: 34px;
	border: 1px solid #ccc;
    border-radius: 7px;
	background-color: #fff;
	font-family: $font;
	text-align: center;
	flex-shrink: 0;
	transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.field_datetime .dateInput input,
.field_date .dateInput input {
	width: 100%;
	outline: none;
	border: none;
	font-family: $font;
	text-align: center;
}

.ant-form-item-has-error .dateInput {
	border-color: #ff7875;
}

.field_datetime .dateInput:hover,
.field_date .dateInput:hover {
	border-color: #2072f2;
}

.field_datetime .dateInput .date,
.field_date .dateInput .date {
	width: 86px;
	padding: 3px 0;
	font-size: 17px;
	text-align: center;
	outline: none;
	white-space: nowrap;
    overflow: hidden;
}

.field_date .dateInput .date {
	padding-top: 4px;
	padding-bottom: 2px;
}

.field_datetime .dateInput.date .date,
.field_date .dateInput.date .date {
	width: 106px;
}

.field_datetime .dateTime .date,
.field_date .dateTime .date {
	padding: 0;
	font-size: 12px;
	opacity: .8;
}

.field_datetime .dateTime .time,
.field_date .dateTime .time {
	font-size: 15px;
	outline: none;
}













.mainSystem .field .withTooltip::after {
	content: attr(data-tooltip);
	opacity: 0;
	visibility: hidden;
	position: absolute;
	z-index: 1;
	max-width: 300px;
	background: white;
	border-radius: 8px;
	border: solid 1px;
	padding: 3px 7px;
	margin-top: -3px;
	font-family: font;
}

.mainSystem .field .withTooltip:hover::after {
	opacity: 1;
	visibility: visible;
}






.mainSystem .field .input {
	min-width: 64px;
	max-width: 86px;
	height: 34px;
	border: 1px solid #ccc;
	border-radius: 7px;
	font-size: 15px;
	outline: none;
    // padding: 7px;
    // padding-right: 8px;
	font-family: 'Inter';
	background-color: #fff;
	white-space: nowrap;
	// overflow: hidden;
	flex-shrink: 0;
	text-align: center;
}

.mainSystem .field .input:hover {
	border-color: #2072f2;
}

.ant-input-disabled,
.ant-input[disabled],
.ant-input-number-input[disabled] {
	color: rgba(0,0,0,.5);
}





.mainSystem .field .input.text {
	max-width: 204px;
}

.mainSystem .field.referrer .input.text,
.mainSystem .field.ad_id .input.text,
.mainSystem .field.first_name .input.text,
.mainSystem .field.developer .input.text {
    width: 194px;
    max-width: 194px;
}








.selectField:hover,
.selectField:focus {
	border-color: #2072f2;
}

.selectField {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	min-width: 70px;
    height: 36px;
    border-radius: 7px;
    font-size: 17px;
    cursor: pointer;
	font-family: $font;
	outline: none;
	padding: 0 14px;
    padding-right: 40px;
	border: 1px solid #ccc;
	background-color: #fff;
	font-size: 16px;
}

.selectField::after {
	content: '';
    position: absolute;
    right: 13px;
    top: 10px;
    width: 9px;
    height: 9px;
    border-top: 1px solid #222;
    border-left: 1px solid #222;
    transform: rotate(-135deg);
}

.selectField.optionsShowed::after {
	transform: rotate(45deg) translate(3px, 3px);
}

.selectField .options {
	position: absolute;
	z-index: 2;
    left: 0px;
    top: 40px;
    background-color: #fff;
	display: none;
	border: 1px solid #a8a8a8;
    border-radius: 7px;
    padding: 10px 0;
	flex-direction: column;
	max-height: 290px;
	overflow: auto;
}

.selectField.optionsShowed .options {
	display: flex;
}

.selectField .selectedOption,
.selectField .option {
	display: flex;
	justify-content: center;
	align-items: center;
}

.selectField .selectedOption {
	display: flex;
	align-items: center;
	white-space: nowrap;
    overflow: hidden;
	justify-content: flex-start;
}

.selectField .option {
	padding: 5px 13px;
	justify-content: flex-start;
}

.selectField .option:hover {
	background-color: #eee;
}

.selectField .index {
	width: 11px;
    text-align: center;
	font-size: 15px;
	margin-right: 15px;
    opacity: .6;
}







.mainSystem .field .multipleChoice {
	display: flex;
	margin-top: 5px;
}

.mainSystem .field .multipleChoice .input {
	width: 45px;
	border-radius: 0;
	border-right: none;
	border-color: #a8a8a8;
}

.mainSystem .field .multipleChoice .input:first-child {
	border-radius: 5px 0 0 5px;
}

.mainSystem .field .multipleChoice .input:last-child {
	border-radius: 0 5px 5px 0;
	border-right: 1px solid #a8a8a8;
}

.mainSystem .field .multipleChoice .input:focus {
	border-color: blue;
}

.mainSystem .field .multipleChoice .input:focus+.input {
	border-left-color: blue;
}










.checkboxField {
	display: inline-flex;
	align-items: center;
	cursor: pointer;
}

.checkboxField .hiddenCheckbox {
	display: none
}

.checkboxField .labelCheck {
	position: relative;
	display: block;
	width: 25px;
	height: 25px;
	margin: 5px 0;
	border: 1px solid #a8a8a8;
	border-radius: 5px;
	outline: none;
	cursor: pointer;
}

.checkboxField .labelCheck::before {
	display: none;
	content: "";
	position: absolute;
	left: 8px;
	top: 3px;
	width: 7px;
	height: 13px;
	border: solid black 2px;
	border-left: none;
	border-top: none;
	transform: rotate(45deg);
}

.checkboxField .labelCheck.checked::before {
	display: block;
	border-color: #fff;
}

.checkboxField .labelCheck.checked {
	display: block;
	background-color: #2072f2;
	border-color: #2072f2;
}









.mainSystem .field .rawBtn {
	position: absolute;
	right: -58px;
	top: 50%;
	transform: translateY(-50%);
	display: none;
	margin-left: 7px;
	font-size: 15px;
	padding: 5px 23px;
	background-color: #e8e8e8;
	border-radius: 20px;
	cursor: pointer;
}

.mainSystem .field:hover .rawBtn {
	display: block;
}

.mainSystem .header .rawBtn {
	display: none;
	margin-left: 30px;
	font-size: 15px;
	padding: 5px 23px;
	background-color: #e8e8e8;
	border-radius: 20px;
	cursor: pointer;
}

.mainSystem .section:hover .rawBtn {
	display: block;
}

.mainSystem .field .link {
	text-decoration: none;
}

.mainSystem .link:hover {
	text-decoration: underline;
}

.mainSystem .section .button {
	margin: 10px 0 10px 20px;
	font-size: 15px;
	padding: 7px 23px;
	border-radius: 7px;
	display: inline-flex;
	font-family: $fontSemiBold;
	font-weight: normal;
}




.mainSystem .field .changeBtn {
	margin: 0;
	padding-bottom: 8px;
}











.textarea {
	width: 100%;
	height: 200px;
	border: 1px solid #ccc;
	border-radius: 7px;
	padding: 10px;
	font-size: 16px;
	resize: none;
}








.mainSystem .fieldSetTitle {
	flex-basis: 80%;
    margin-left: 20px;
    border-bottom: 1px solid #ccc;
    padding: 7px 0;
}









.leftPanelSide {
	width: 270px;
    flex-shrink: 0;
}

.leftPanel {
	position: fixed;
	top: 96px;
}

.leftPanel .button {
	width: 180px;
	font-size: 14px;
	margin: 0 10px;
    margin-bottom: 20px;
	text-decoration: none;
}





















// EXTRA
.field_battlePassTasks::after {
	content: '';
	position: absolute;
    left: -21px;
    top: 6px;
    width: 28px;
    height: 32px;
	background-image: url(/img/bp_point.png);
	background-size: contain;
    background-repeat: no-repeat;
}