.page-reviewsErrors .form {
	min-width: 300px;
	display: inline-block;
}

.page-reviewsErrors .form .button {
	height: 50px;
	margin-top: 20px;
}

.page-reviewsErrors .form .table {
	width: 1400px;
	margin-top: 20px;
	text-align: center;
}

.page-reviewsErrors .form .tableContainer {
	max-height: 500px;
	overflow: auto;
}

.page-reviewsErrors .form .loadingSpinner {
    position: absolute;
    top: 100px;
    left: 44%;
	width: 50px;
    height: 50px;
}

.page-reviewsErrors td.review {
	max-width: 600px;
	word-break: break-word;
	text-align: left;
}

.page-reviewsErrors td.email {
	max-width: 100px;
	word-break: break-word;
}

.page-reviewsErrors .map {
	width: 50px;
	text-align: center;
}

.page-reviewsErrors .rev {
	text-align: center;
}

.page-reviewsErrors td.time {
	width: 108px;
}

.page-reviewsErrors .platform {
	text-align: left;
}








.page-reviewsErrors .ant-table {
	margin-top: 20px;
}

.page-reviewsErrors .form > .content {
	max-width: 350px;
}


.page-reviewsErrors .link {
	color: #0152c3;
}

.page-reviewsErrors .link:hover {
	text-decoration: underline;
}