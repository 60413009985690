.podarokModal .mainForm .ant-form-item {
	margin: 11px 0;
}

.podarokModal .content {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
}

.podarokModal .ant-form,
.podarokModal .rewardsBox {
	flex: 1
}

.podarokModal .rewardsBox {
	margin-left: 20px;
}

.podarokModal .mainForm .ant-form-item {
	width: 316px;
}

.podarokModal .rewardsBox .ant-form-item {
	width: 200px;
}








.podarokModal .reward {
	position: relative;
	display: flex;
	align-items: center;
	border-radius: 7px;
	padding: 3px 10px;
	padding-right: 8px;
	justify-content: space-between;
}

.podarokModal .reward .label {
	display: flex;
	align-items: center;
}

.podarokModal .reward .name {
	margin-left: 5px;
	opacity: 0.6;
}

.podarokModal .reward .value {
	margin-right: 25px;
	width: 50px;
}

.podarokModal .reward:hover {
	background-color: #fbfbfb;
}

.podarokModal .deleteBtn {
	position: absolute;
	right: 6px;
    top: 7px;
	opacity: 0.4;
	cursor: pointer;
}

.podarokModal .deleteBtn:hover {
	opacity: 1;
}

.podarokModal .ant-form-item-required::before {
	display: none!important;
}






.podarokModal .rewardsBox {
	border: 1px solid #ccc;
	border-radius: 7px;
	padding: 10px;
}

.podarokModal .rewardsBox .addBox {
	display: flex;
	align-items: flex-start;
	margin-top: 20px;
}

.podarokModal .rewardsBox .title {
	display: block;
	font-size: 16px;
	margin-bottom: 10px;
}

.podarokModal .submitBtn {
	margin-top: 20px;
	height: 40px;
	font-size: 15px;
	width: 150px;
}

.podarokModal .ant-tabs-nav-add {
	padding: 8px 16px!important;
}

.podarokFieldWarn {
	border-bottom: 2px dashed #ccc;
	font-family: $font;
}

.podarokFieldWarn:hover {
	border-bottom-color: transparent;
}

.field_podarok {
	width: 100%;
}

.field_podarok .leftSide {
	display: none!important;
}

@media (max-width: 768px) {
	.ant-modal .ant-modal-content {
		padding: 20px 14px;
	}

	.podarokModal .rewardsBox {
		margin: 0;
		margin-top: 20px;
	}

	.podarokModal .rewardsBox {
		padding: 2px;
	}
}