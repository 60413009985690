@import "vars";

.popup {
	position: fixed;
	z-index: 13;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
	transition: opacity .25s;
}

.popup .overlay {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.2);
}

.popup .content {
	position: absolute;
    left: 50%;
    top: 10%;
	transform: translateX(-50%);
    max-width: 480px;
    background-color: #fff;
    border-radius: 7px;
	padding: 20px;
}

.popup .closeBtn {
	position: absolute;
	right: 0;
	top: 0;
	width: 30px;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #000;
	font-size: 36px;
    transform: rotate(45deg);
    cursor: pointer;
}

.popup .popupTitle {
	display: flex;
    align-items: center;
    margin-bottom: 30px;
	font-size: 18px;
	font-weight: normal;
	font-family: $fontSemiBold;
}

.popup .closingParths {
	margin-top: 20px;
	font-size: 18px;
	font-weight: normal;
	font-family: $fontSemiBold;
}

.popup .popupTitle .type {
	font-size: 14px;
    margin-left: 10px;
    opacity: .5;
    font-weight: normal;
}





.popup .input {
	height: 34px;
}











.popup_rawField .rawField {
	font-size: 16px;
    resize: none;
    height: 200px;
    width: 336px;
    border: none;
	border: 1px solid #ccc;
    padding: 4px;
}

.popup_rawField .saveBtn {
	margin-top: 20px;
    height: 50px;
}





@import "./popups/editListPopup";
@import "./popups/cinemaTasksPopup";
@import "./popups/battlePassTasksPopup";
@import "./popups/featuresPointsPopup";
@import "./modals/interstitialAdModal";
@import "./modals/battlePassRewardsModal";
@import "./modals/villaActionsModal";
@import "./modals/snapshotsModal";
@import "./modals/podarokModal";
@import "./modals/infoPopups";