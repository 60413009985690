@font-face {
	font-family: 'ALSGorizont-RegularExpanded';
	src: url('/fonts/ALSGorizont-RegularExpanded.woff2') format('woff2');
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: 'Inter';
	src: url('/fonts/Inter-Regular.woff') format('woff');
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: 'Inter Semibold';
	src: url('/fonts/Inter-SemiBold.woff') format('woff');
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: 'Inter Bold';
	src: url('/fonts/Inter-Bold.woff') format('woff');
	font-style: normal;
	font-weight: normal;
}

$font: 'Inter', sans-serif;
$fontBold: 'Inter Bold', sans-serif;
$fontSemiBold: 'Inter Semibold', sans-serif;
$fontDecor: 'ALSGorizont-RegularExpanded', sans-serif;


$findColor: #8d84e8;
$catColor: #5da283;
$tripColor: #dbb303;
$villaColor: #fc979a;
$aliceColor: #aecf55;
$wordsColor: #4ecbc4;
$sweetColor: #b36bd4;
$cargoColor: #b36bd4;
$houseColor: #b36bd4;
$fishColor: #f06a6a;
$alienColor: #f26fb2;
$birdColor: #f9aaef;
$waterColor: #8d84e8;
$uwColor: #f9ae67;