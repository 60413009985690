.popup_featuresPoints .content {
	position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.popup_featuresPoints .features {
	max-height: 500px;
	overflow: auto;
	padding-right: 15px;
}

.popup_featuresPoints .app {
 	margin: 0 5px;
}