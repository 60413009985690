.ant-message .ant-message-notice .ant-message-custom-content>.anticon {
	font-size: 23px;
}

.ant-message .ant-message-notice .ant-message-notice-content {
	font-size: 16px;
}

.ant-message {
	max-width: 576px;
}