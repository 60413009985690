.popup_editList .content {
	padding-top: 30px;
}

.popup_editList .listBox .index {
	height: 34px;
}

.popup_editList .listBox.table {
	max-height: 418px;
    overflow: auto;
}

.popup_editList .listBox .field .name {
	display: none;
}

.popup_editList .listBox .item {
	display: flex;
    align-items: baseline;
	margin-bottom: 8px;
	margin-top: 20px;
}

.popup_editList .listBox .index {
	font-size: 16px;
	width: 34px;
    text-align: center;
    border: none;
    font-family: $font;
    color: rgba(0,0,0,.6);
	border: 1px solid #ccc;
	border-radius: 7px;
	margin-right: 8px;
}

.popup_editList .listBox .index:hover,
.popup_editList .listBox .addBox .index {
	border-color: #ccc;
}

.popup_editList .listBox .addBtn {
	border: 1px solid #ccc;
    height: 34px;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 109px;
    margin-left: 8px;
	cursor: pointer;
	font-size: 15px;
    padding-bottom: 4px;
	font-family: $font;
}

.popup_editList .listBox .deleteBtn {
	opacity: 0;
}

.popup_editList .listBox .ant-table-row:hover .deleteBtn {
	opacity: 1;
}






.popup_editList .saveBtn {
	margin-top: 20px;
	height: 45px;
}





.popup_editList .ant-select {
	width: 181px;
}

.popup_editList .ant-input-number {
	text-align: left;
	padding-left: 0;
}