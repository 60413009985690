.page-copy .copyForm {
	margin: 0 auto;
	max-width: 350px;
}

.page-copy .copyForm .button {
	height: 50px;
	width: 100%;
	margin-top: 20px;
	font-size: 15px;
	font-family: $font;
}

.page-copy .selectField .options {
	width: 100%;
}