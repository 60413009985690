.page-coinsStats .formTable {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.page-coinsStats .feather {
	position: relative;
    top: 4px;
	margin: -3px 0;
}

.page-coinsStats .form {
	position: sticky;
    top: 84px;
	z-index: 1;
	display: inline-block;
	margin-right: 30px;
}

.page-coinsStats .field_date {
	margin: 16px 0;
}

.page-coinsStats .showBtn {
	margin-top: 17px;
	height: 50px;
}

.page-coinsStats .toDate {
	margin-left: 16px;
}





.page-coinsStats .coinsHistoryTable {
	margin-top: 20px;
}

.page-coinsStats .comment {
	width: 280px;
}

.page-coinsStats .coinsHistoryTable.wide {
	width: 1112px;
}

.page-coinsStats .user_balans_do,
.page-coinsStats .user_balans_posle,
.page-coinsStats .balans {
	text-align: center;
}







.page-coinsStats .loadingSpinner {
	margin: 0 auto;
	margin-top: 30px;
}

.page-coinsStats .tableContainer .loadingSpinner {
	position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
	margin: 0;
}


.page-coinsStats .ant-table {
	margin-top: 4px;
}

.page-coinsStats .ant-pagination-options {
	display: none;
}




@media (max-width: 1200px) {
	.page-coinsStats .formTable {
		flex-wrap: wrap;
	}

	.page-coinsStats .form {
		position: relative;
		top: 0;
		margin-right: 0;
		width: 100%;
		margin-bottom: 40px;
	}
}