@import	'vars';

#header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 12;
	width: 100%;
	font-family: $font;
}

#header .appName {
	display: none;
}

#header .topSection {
	background-color: #f0f2f6;
}

#header .section {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

#header .top {
	display: flex;
	justify-content: space-between;
}

#header .headerTitle {
    display: flex;
	font-size: 25px;
	letter-spacing: 1.5px;
	justify-content: space-between;
	margin-bottom: 50px;
}

#header .headerTitle a {
	text-decoration: none;
}








#header .appsSection {
	position: absolute;
    left: 0;
    top: 39px;
    width: 100%;
	display: none;
	background-color: #fbfbfb;
}

#header .appsSection.show {
	display: block;
	border-bottom: 2px solid #f1f1f1;
}









#header .pagesLinks {
	display: flex;
	align-items: center;
}

#header .pagesLinks .link {
	display: flex;
	height: 39px;
	align-items: center;
	padding: 11px 16px;
	font-size: 14px;
	font-family: $font;
	text-decoration: none;
	cursor: pointer;
	white-space: nowrap;
	border-radius: 7px;
}

#header .topSection .pagesLinks .link:hover {
	text-decoration: underline;
	background-color: #e6e9ef;
}

#header .topSection .pagesLinks .link:not(.apps).active {
	background-color: #2072f2;
	color: #fff;
}

#header .link.apps .feather {
	margin-top: 1px;
	margin-left: 7px;
	margin-right: 0;
}

#header .link.apps.active .feather {
	transform: rotate(-180deg);
}

#header .pagesLinks .link:not(.apps).active {
	cursor: default;
	text-decoration: none;
	pointer-events: none;
}

#header .pagesLinks .link.apps {
	padding-right: 5px;
}

#header .pagesLinks .link .feather {
	opacity: 0.6;
	margin-right: 7px;
}







#header .appsMenu {
	position: relative;
	display: flex;
	font-size: 18px;
	margin-left: 20px;
}

#header .appsMenu .item {
	position: relative;
	padding: 7px 15px;
}

#header .appsMenu .item .titleName,
#header .appsMenu .item.all {
	font-size: 16px;
}


#header .appsMenu .item.all {
	font-family: $fontSemiBold;
}

#header .appsMenu .item.all:hover {
	text-decoration: none;
}




#header .appsMenu .projectTitle {
	display: none;
}

#header .appsMenu .projectName {
	display: flex;
	align-items: center;
	cursor: default;
	font-size: 15px;
	text-transform: uppercase;
	letter-spacing: .5px;
}

#header .appsMenu .projectName::before {
	content: '';
	display: inline-block;
    margin-right: 8px;
    width: 13px;
    height: 13px;
    border-radius: 4px;
	background-color: #eee;
}

#header .item.find .projectName::before {
	background-color: $findColor;
	box-shadow: 0 0 5px -1px $findColor;
}

#header .item.cat .projectName::before {
	background-color: $catColor;
	box-shadow: 0 0 5px -1px $catColor;
}

#header .item.trip .projectName::before {
	background-color: $tripColor;
	box-shadow: 0 0 5px -1px $tripColor;
}

#header .item.villa .projectName::before {
	background-color: $villaColor;
	box-shadow: 0 0 5px -1px $villaColor;
}

#header .item.alice .projectName::before {
	background-color: $aliceColor;
	box-shadow: 0 0 5px -1px $aliceColor;
}

#header .item.words .projectName::before {
	background-color: $wordsColor;
	box-shadow: 0 0 5px -1px $wordsColor;
}

#header .item.sweet .projectName::before {
	background-color: $sweetColor;
	box-shadow: 0 0 5px -1px $sweetColor;
}

#header .item.alien .projectName::before {
	background-color: $alienColor;
	box-shadow: 0 0 5px -1px $alienColor;
}

#header .item.fish .projectName::before {
	background-color: $fishColor;
	box-shadow: 0 0 5px -1px $fishColor;
}

#header .item.cargo .projectName::before {
	background-color: $cargoColor;
	box-shadow: 0 0 5px -1px $cargoColor;
}

#header .item.water .projectName::before {
	background-color: $waterColor;
	box-shadow: 0 0 5px -1px $waterColor;
}

#header .item.house .projectName::before {
	background-color: $houseColor;
	box-shadow: 0 0 5px -1px $houseColor;
}

#header .item.uw .projectName::before {
	background-color: $uwColor;
	box-shadow: 0 0 5px -1px $uwColor;
}

#header .topSection .linksBlock {
    position: absolute;
    left: 11px;
    top: 34px;
    z-index: 1;
    display: none;
    padding: 15px 24px;
    background-color: #fff;
    border-radius: 0 0 7px 7px;
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(0, 0, 0, 0.06);
}

#header .appsMenu .item:hover .linksBlock {
	display: block;
}

#header .topSection .linksBlock .projectName {
    display: none;
}

// #header .topSection .linksBlock .projectName::before {
// 	display: none;
// }



#header .platforms,
#header .versions {
	display: flex;
}

#header .platform > .title {
	margin-bottom: 18px;
	padding: 5px 0;
	font-size: 16px;
	text-transform: capitalize;
	font-family: $fontSemiBold;
	white-space: nowrap;
	text-transform: uppercase;
}

#header .platform.web > .title {
	color: #fb4c4c
}

#header .platform.mobile > .title {
	color: #2dc33a;
}




#header .platform + .platform {
	margin-left: 20px;
	border-left: 1px solid #f1f1f1;
    padding-left: 20px;
}

#header .platform .link {
	font-family: $font;
}







#header .versionsItem + .versionsItem {
	margin-left: 45px;
}

#header .versionsItem .title {
	font-size: 16px;
	margin-bottom: 10px;
	text-transform: uppercase;
	font-family: $fontSemiBold;
}

#header .versionsItem .links {
	display: flex;
	flex-direction: column;
}

#header .versionsItem .link {
	position: relative;
	left: -9px;
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	font-size: 16px;
	white-space: nowrap;
	text-transform: uppercase;
    border-radius: 6px;
	padding: 0 4px;
}

#header .versionsItem .link:hover {
	background-color: #f3f3f3;
}

#header .platform.mobile .link a {
	text-decoration: none;
	display: block;
    width: 100%;
    padding: 6px 10px
}






#header .versionsItem .appLink {
	display: flex;
	width: 100%;
	padding: 6px 6px;
	justify-content: space-between;
}

#header .versionsItem .appLink a {
	text-decoration: none;
}

#header .versionsItem .appLink .label {
	display: none;
}

#header .versionsItem .link:hover {
	text-decoration: underline;
}

#header .versionsItem .link.disabled {
	padding: 4px 10px;
	cursor: default;
}

#header .versionsItem .link.disabled .titleName {
	opacity: 0.6;
}

#header .versionsItem .link.disabled:hover {
	text-decoration: none;
}

#header .socialTitle {
	display: none;
}

#header .socialLink {
	position: relative;
	top: 3px;
	opacity: 0;
}

#header .socialLink:hover {
	color: #1677ff;
	text-decoration: underline;
}

#header .ant-menu-item:hover .socialLink,
#header .ant-menu-submenu-title:hover .socialLink {
	opacity: 1;
}












#header .extraLinks {
	flex-grow: 1;
    padding: 7px;
    padding-left: 100px;
}

#header .extraLinks .link {
	font-size: 16px;
	text-decoration: none;
	color: inherit;
}








#header .profilePanel {
	margin-right: 80px;
	white-space: nowrap;
}

#header .profilePanel,
#header .profilePanel .nameID {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: right;
	font-size: 14px;
}

#header .profilePanel .name {
	border-bottom: 1px solid #a5a5a5;
}

#header .nameID:hover .name {
	border-color: rgba(0,0,0,0);
}

#header .profilePanel .idInfo {
	margin-left: 14px;
	opacity: 0.5;
}

#header .profilePanel .name,
#header .profilePanel .idInfo {
	position: relative;
	z-index: 2;
}

#header .profilePanel .exitBtn {
	position: absolute;
	right: -80px;
	top: 0;
	z-index: 1;
	display: inline-block;
	opacity: .5;
	border-bottom: 1px solid #222;
	cursor: pointer;
}

#header .profilePanel .exitBtn:hover {
	border-bottom-color: rgba(0,0,0,0);
}


#header .profilePanel .socialIDs {
	position: absolute;
	right: -28px;
    top: -16px;
	z-index: 1;
	display: none;
}

#header .socialIDs .items {
	padding: 10px;
	margin-top: 50px;
	padding-right: 5px;
	background-color: #fff;
	overflow: auto;
	border-radius: 7px;
    max-height: 328px;
    box-shadow: 0 0 10px -3px rgba(0,0,0,.2);
}

#header .socialIDs .item {
	text-align: left;
	font-family: $font;
	width: 100%;
    overflow: hidden;
	cursor: pointer;
    text-overflow: ellipsis;
	border-radius: 5px;
	padding: 5px;
}

#header .nameID:hover .socialIDs {
	display: block;
}

#header .socialIDs .item {
	display: flex;
	justify-content: space-between;
}

#header .socialIDs .ids {
	position: relative;
	margin-right: 20px;
	margin-left: 9px;
	border-bottom: 1px solid #a5a5a5;
}

#header .socialIDs .item:hover {
	background-color: #f5f5f5;
}

#header .socialIDs .item:hover .ids {
	border-color: rgba(0,0,0,0);
}

#header .socialIDs .ids .feather {
    position: absolute;
    right: -23px;
    top: 1px;
    width: 19px;
    height: 17px;
	opacity: 0;
}

#header .socialIDs .item:hover .feather {
	opacity: .6;
}





#header .mobileLinks {
	display: none;
	align-items: center;
}

#header .mobileLinks .link {
	font-size: 14px;
	margin-right: 20px;
}




.appsLinksMenu .ant-menu-item {
	padding: 0!important;
	display: flex;
	justify-content: center;
	text-align: center;
	height: 28px;
	font-size: 15px;
}

.appsLinksMenu .ant-menu-submenu-arrow {
	display: none;
}

.appsLinksMenu .ant-menu-submenu-title,
.appsLinksMenu.ant-menu-root > .ant-menu-item-only-child {
	width: 130px;
    height: 31px!important;
    font-size: 17px;
    left: -11px;
    padding: 0px 7px!important;
	font-family: $font!important;
	text-align: left;
}

.appsLinksMenu .ant-menu-title-content {
	font-family: $font!important;
}

.appsMenu.home .appsLinksMenu.ant-menu-root > .ant-menu-item-only-child,
.appsMenu.home .appsLinksMenu .ant-menu-submenu-title {
	padding-left: 20px!important;
}

.appsMenu.home .feather {
	display: none;
}

.appsMenu.home .ant-menu-item,
.appsMenu.home .ant-menu-submenu-title {
	width: auto;
}

.appsMenu.home .ant-menu-item .label {
	margin-left: 15px;
}








@media (max-width: 1448px) {
	#header .pagesLinks .link {
		font-size: 13px;
		padding-left: 6px;
		padding-right: 6px;
	}

	#header .pagesLinks .link .feather {
		display: none;
	}

	#header .appsMenu .item {
		padding: 7px 10px;
	}
}

@media (max-width: 992px) {
	#header .appsSection {
		display: none!important;
	}

	#header .mobileLinks {
		display: flex;
	}

	#header .appsSection {
		top: 35px;
	}

	#header .pagesLinks {
		display: none;
	}

	#header .topSection {
		padding: 12px 0;
	}

	#header .profilePanel {
		position: relative;
		top: 0;
		right: 0;
		font-size: 13px;
		margin-right: 0;
	}

	#header .profilePanel .exitBtn {
		position: relative;
		right: 0;
		margin-left: 15px;
	}
}

@import "./navbar/navbarPopup";