.form {
	padding: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 0 18px -3px rgba(0,0,0,.12);
    border-radius: 7px;
	text-align: left;
}

.form .ant-input-number {
	display: flex;
	width: 100%;
}

.form > .title {
	font-size: 17px;
	font-family: $font;
	margin-bottom: 30px;
}

.form .formFieldItem {
	margin: 25px 0;
}

.form .fieldLabel {
	display: inline-block;
	margin-bottom: 8px;
	font-size: 15px;
	font-family: $fontSemiBold;
}

.form .input {
	width: 100%;
	height: 34px;
}

.form .ant-select {
	width: 100%;
}

.form .field_date .dateInput .date {
	padding-top: 7px;
    padding-bottom: 4px;
}





.form .selectField .options {
	width: 100%;
}







.form .fieldsGroup > .title {
	font-size: 15px;
	font-family: $fontSemiBold;
}

.form .fieldsGroup .group {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.form .ant-btn {
	width: 100%;
	font-family: $font;
}

.form .ant-btn-primary {
	font-size: 16px;
}