.ant-input-number-input-wrap {
	width: 100%;
}

.ant-input {
	font-size: 15px;
}

input::placeholder {
	color: #999!important
}