@import "./login";
@import "./home";
@import "./notFound";
@import "./reviews";
@import "./copy";
@import "./refund";
@import "./project";
@import "./featuresPoints";
@import "./coinsStats";
@import "./payments";
@import "./linksGenerator";
@import "./setGift";
@import "./reviewsErrors";
@import "./setCrystals";
@import "./dictionary";
@import "./addition";

.pageContainer {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100%;
}

.pageContainer > .top {
	padding-top: 150px;
}

.pageContainer footer {
	margin-top: 160px;
}

.pageTitle {
	font-size: 21px;
	font-family: $fontSemiBold;
}






@media (max-width: 992px) {
	.page-dictionary .dictionaryTable {
		width: 100%!important;
	}
}