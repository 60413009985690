.page-setGift .setGiftForm {
	z-index: 1;
}

.page-setGift .setGiftForm .formFieldItem {
	display: flex;
	flex-direction: column;
}

.page-setGift .setGiftForm .ant-input-number {
	width: 100%;
}

.page-setGift .setGiftForm .button {
	height: 50px;
	margin-top: 20px;
}




.page-setGift .content {
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.page-setGift .ant-table {
	margin-top: 10px;
}



.page-setGift .form {
	width: 270px;
}













.page-setGift .form .giftMessage .options {
	width: 560px;
}



.page-setGift .loadingSpinner {
    position: absolute;
    left: 43%;
    top: 30%;
}

.page-setGift .ant-pagination-options {
	display: none;
}









@media (max-width: 1200px) {
	.page-setGift .content {
		flex-wrap: wrap;
	}

	.page-setGift .ant-table-wrapper {
		width: 100%;
	}

	.page-setGift .form {
		width: 100%;
		margin-bottom: 30px;
	}

	.page-setGift .form .giftMessage .options {
		width: 100%;
	}
}