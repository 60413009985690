.ant-table {
	overflow: auto;
}

// .ant-table-row.green td {
// 	background-color: rgb(83 255 83 / 22%);
// }

// .ant-table-row.yellow td {
// 	background-color: rgb(254 255 83 / 22%);
// }

// .ant-table-row.red td {
// 	background-color: rgb(255 83 83 / 22%);
// }

// .tableContainer {
// 	position: relative;
// }

// .table {
// 	border-collapse: collapse;
// 	font-size: 14px;
// }

// .table.fadeOut {
// 	opacity: .6;
// 	pointer-events: none;
// }

// .table th {
// 	text-align: left;
// 	font-family: $fontSemiBold;
// }

// .table th,
// .table td {
// 	padding: 10px;
// 	line-height: 19px;
// }

// .table tr:nth-child(2n) {
// 	background-color: #f9f9f9;
// }

// .table tr:not(:first-child):hover {
// 	background-color: #edf4ff;
// }

// .table tr:first-child {
// 	position: sticky;
//     top: 0px;
//     background-color: #fff;
// }