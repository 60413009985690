.page-payments .form {
	display: inline-block;
}

.page-payments .showBtn {
	margin-top: 17px;
	height: 50px;
}

.page-payments .ant-table {
	margin-top: 20px;
}

.page-payments .amount {
	text-align: center;
}


