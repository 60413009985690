.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	font-weight: normal;
	font-family: $fontSemiBold;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 34px;
    padding: 1px 13px;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
	color: inherit
}

.ant-form-item {
	margin-bottom: 0;
}

.ant-select-selection-search-input {
	font-size: 15px;
}
