.popup_cinemaTasks .content {
	min-width: 543px;
	max-width: unset!important;
}

.popup_cinemaTasks .tasks {
	max-height: 510px;
	overflow: auto;
}

.popup_cinemaTasks .tasks.wide {
	padding-right: 20px;
}

.popup_cinemaTasks .taskItem {
	position: relative;
	margin-bottom: 17px;
	border: 1px solid #ccc;
    border-radius: 7px;
    padding: 10px 15px;
}

.popup_cinemaTasks .taskItem .top {
	display: flex;
	align-items: center;
	font-size: 14px;
	font-family: $fontSemiBold;
	border-bottom: 1px solid #ccc;
	padding-bottom: 6px;
	margin-bottom: 15px;
	white-space: pre;
}

.popup_cinemaTasks .taskItem .id {
	font-size: 18px;
}

.popup_cinemaTasks .top > div {
	margin-right: 15px;
}

.popup_cinemaTasks .taskItem .deleteBtn {
	position: absolute;
    right: 7px;
    top: 2px;
    font-family: $font;
    font-size: 40px;
    transform: rotate(45deg);
    font-weight: 100;
    width: 20px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
	opacity: 0.3;
	overflow: hidden;
	cursor: pointer;
}

.popup_cinemaTasks .taskItem .deleteBtn:hover {
	color: red;
	opacity: 0.6;
}

.popup_cinemaTasks .cinemaTaskProgress .input {
	width: 70px;
}





.popup_cinemaTasks .cinemaTaskStatus .ant-select {
	width: 190px;
	white-space: pre;
}













.popup_cinemaTasks .bottom {
	display: flex;
	align-items: flex-start;
}

.popup_cinemaTasks .tasks .field {
	margin-right: 8px;
}

.popup_cinemaTasks .field .name {
	display: none;
}

.popup_cinemaTasks .field .title {
	font-size: 14px;
	opacity: 0.8;
	margin-bottom: 5px;
	white-space: pre;
}









.popup_cinemaTasks .controls {
	margin-bottom: 20px;
}

.popup_cinemaTasks .controls .group {
	display: flex;
	align-items: center;
}

.popup_cinemaTasks .controls .button {
    font-size: 14px;
    margin-right: 17px;
    margin-top: 14px;
	height: 36px;
	white-space: pre;
}

.popup_cinemaTasks .controls .addBtn {
	margin-top: 20px;
    margin-left: 10px;
}

.popup_cinemaTasks .controls .saveBtn {
	margin-top: 20px;
}

.popup_cinemaTasks .cinemaNewTasks .ant-select {
	width: 200px;
}