@media (max-width: 992px) {
	.leftPanelSide {
		display: none;
	}
}

@media (max-width: 992px) {
	#header .menu {
		display: none;
	}

	#header .appName {
		display: block;
	}

	.mainSystem .field {
		width: 100%!important;
		margin-right: 0;
	}

	.mainSystem .mainTitle {
		font-size: 19px;
	}

	.mainSystem .mainTitle .id {
		font-size: 14px;
	}

	.mainSystem .fields .leftSide {
		flex-direction: column;
    	align-items: baseline;
	}





	.mainSystem .fields .name {
		margin-left: 0;
	}

	.mainSystem .field_text .inner,
	.mainSystem .field_datetime .inner {
		flex-direction: column;
		align-items: baseline;
	}
	
	.mainSystem .field.field_datetime .dateInput {
		margin-top: 10px;
	}

	.mainSystem .field.field_text .input {
		width: 100%!important;
		max-width: 100%!important;
		margin-top: 10px;
	}

	.mainSystem .header .rawBtn {
		display: block;
		margin-right: 20px;
	}


	.mainSystem .fields .leftSide .title {
		font-size: 15px;
	}




	.mainSystem .subgroup > .title {
		margin-left: 10px;
	}









	#bottomPanel .buttons {
		margin: 0;
		background: none;
		margin-bottom: 10px;
		padding: 0;
	}







	#bottomPanel .button {
		padding: 14px 42px;
		padding-bottom: 16px;
		font-size: 14px;
	}







	.selectField .options {
		left: auto;
		right: 0;
	}





	.mainSystem .field {
		padding-right: 0;
	}

	.mainSystem .field .input {
		text-align: center;
	}

	.mainSystem .field .labelCheck {
		margin-right: 0;
	}

	.mainSystem .fields .noData {
		margin-left: 10px;
	}







	.popup .content {
		top: 50%;
		padding: 20px;
		max-width: 97%;
		transform: translate(-50%, -50%);
	}


	.popup_cinemaTasks .content {
		transform: scale(.7) translate(-50%, -50%);
    	transform-origin: left top;
	}


	.popup .listBox {
		max-height: 252px;
	}

	.popup .listBox .input {
		width: 162px;
	}

	.popup .listBox .deleteBtn {
		opacity: 1;
	}









	.project-page .helpButtons {
		flex-direction: column;
		align-items: baseline;
	}

	.project-page .helpButtons .button {
		margin: 5px 0;
	}
}

@media (max-width: 1200px) {
	#header .menu .item {
		margin-right: 30px;
	}
}

@media (max-width: 768px) {
	#bottomPanel .saveBtn.changed .label {
		top: -20px;
		font-size: 12px;
	}

	#bottomPanel .button {
		padding: 0;
		margin: 7px;
		width: 167px;
		height: 48px;
	}




	
	.leftPanel {
		position: relative;
		top: 0;
		width: 100%;
	}

	#header .headerTitle {
		font-size: 20px;
	}

	#header .extraLinks {
		display: none;
	}











	.mainSystem .field .inner {
		padding-left: 9px;
	}

	.section .header .top {
		margin-left: 9px;
	}

	.mainSystem .header,
	.mainSystem .field {
		width: 100%;
	}






	.form {
		width: 100%;
	}

	.table {
		white-space: pre;
	}
}