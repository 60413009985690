.loginPage {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
	background-image: url(/img/bg.jpg);
}

.loginPage .sherlock {
	position: absolute;
	left: 53px;
    top: -138px;
    width: 260px;
    height: 230px;
	pointer-events: none;
	background-image: url(/img/pages/login/sherlock.png);
}