.page-addition .additionForm {
	margin: 0 auto;
	max-width: 350px;
}

.page-addition .additionForm .button {
	height: 50px;
	width: 100%;
	margin-top: 20px;
	font-size: 15px;
	font-family: $font;
}

.page-addition .selectField .options {
	width: 100%;
}