.battlePassTasksModal .top {
	display: flex;
	align-items: flex-end;
	flex-wrap: wrap;
}

.battlePassTasksModal .top .button {
	margin-top: 10px;
	margin-right: 10px;
	font-size: 14px;
}

.battlePassTasksModal .top .addBtn {
	margin-top: 0;
	margin-left: 14px;
}

.battlePassTasksModal .top .saveBtn {
	margin-top: 15px;
	margin-right: 0;
}

.battlePassTasksModal .field .title {
	margin-bottom: 8px;
}

.battlePassTasksModal .battlePassTaskID .ant-select {
	width: 305px;
	justify-content: flex-start;
}





.battlePassTasksModal .field.status .ant-select {
	width: 171px;
}












.battlePassTasksModal .tasks {
	height: 624px;
    overflow: auto;
}

.battlePassTasksModal .tasks .empty {
	width: 100%;
    text-align: center;
    font-size: 30px;
    margin-top: 50px;
    opacity: .56;
}

.battlePassTasksModal .taskItem {
	position: relative;
	display: inline-block;
	border: 1px solid #ccc;
	padding: 10px 12px;
	border-radius: 7px;
	margin-top: 20px;
	font-size: 16px;
}

.battlePassTasksModal .taskItem .title {
	display: flex;
	align-items: center;
	font-family: $fontSemiBold;
	font-size: 15px;
	margin-bottom: 10px;
}

.battlePassTasksModal .taskItem .name {
	font-family: $font;
	opacity: 0.6;
	margin-left: 15px;
}

.battlePassTasksModal .taskItem .description {
	display: flex;
	align-items: center;
	font-family: $fontSemiBold;
	margin-bottom: 3px;
}

.battlePassTasksModal .taskItem .reward {
	display: flex;
	align-items: center;
}

.battlePassTasksModal .taskItem .reward::after {
	content: '';
	width: 25px;
    height: 28px;
	margin-left: 5px;
	background-image: url(/img/bp_point.png);
	background-repeat: no-repeat;
	background-size: contain;
}

.battlePassTasksModal .taskItem .subgroup {
	display: flex;
	justify-content: flex-start;
}













.battlePassTasksModal .fields {
	margin-top: 20px;
	display: flex;
	align-items: flex-start;
}

.battlePassTasksModal .ant-input-number {
	font-size: 15px;
}

.battlePassTasksModal .fields .field {
	margin-right: 15px;
}








.battlePassTasksModal .tasks .options {
	width: 100%;
}









.battlePassTasksModal .taskItem .deleteBtn {
	position: absolute;
    right: 7px;
    top: 2px;
    font-family: $font;
    font-size: 40px;
    transform: rotate(45deg);
    font-weight: 100;
    width: 20px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
	opacity: 0.3;
	overflow: hidden;
	cursor: pointer;
}

.battlePassTasksModal .taskItem .deleteBtn:hover {
	color: red;
	opacity: 0.6;
}










.battlePassTasksModal .field.difficulty .ant-select {
	width: 100%;
}









