@import "vars";

#appMessage {
    position: fixed;
	left: 50%;
    top: 50px;
	z-index: 10;
    width: 400px;
    padding: 13px 15px;
    border-radius: 7px;
    background-color: #53bf3f;
    color: #fff;
    text-shadow: 0 0 3px rgb(0 0 0 / 21%);
    transform: translateX(-50%);
	opacity: 0;
	pointer-events: none;
	transition: opacity .4s;
}

#appMessage.show {
	opacity: 1;
}