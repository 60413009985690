.page-home .pageTitle {
	margin-left: 20px;
}

.appsMenu.home {
	text-align: center;
}

.appsMenu.home .item {
	position: relative;
	display: inline-block;
	width: 466px;
	padding: 13px 17px;
	border: 1px solid #e4e4e4;
	border-radius: 7px;
	font-size: 14px;
	margin: 20px;
	text-align: left;
	vertical-align: top;
}

.appsMenu.home .item.find {
	border: 1px solid $findColor;
}

.appsMenu.home .item.cat {
	border: 1px solid $catColor;
}

.appsMenu.home .item.trip {
	border: 1px solid $tripColor;
}

.appsMenu.home .item.villa {
	border: 1px solid $villaColor;
}

.appsMenu.home .item.alice {
	border: 1px solid $aliceColor;
}

.appsMenu.home .item.words {
	border: 1px solid $wordsColor;
}

.appsMenu.home .item.sweet {
	border: 1px solid $sweetColor;
}

.appsMenu.home .item.fish {
	border: 1px solid $fishColor;
}

.appsMenu.home .item.fish {
	border: 1px solid $fishColor;
}

.appsMenu.home .item.alien {
	border: 1px solid $alienColor;
}

.appsMenu.home .item.cargo {
	border: 1px solid $cargoColor;
}

.appsMenu.home .item.water {
	border: 1px solid $waterColor;
}

.appsMenu.home .item.house {
	border: 1px solid $houseColor;
}

.appsMenu.home .item.uw {
	border: 1px solid $uwColor;
}









.appsMenu.home .item .titleName {
	display: flex;
	align-items: center;
}

.appsMenu.home .item .projectTitle {
	font-size: 16px;
	font-family: $fontSemiBold;
}

.appsMenu.home .item .projectName {
	margin-left: 10px;
	opacity: 0.4;
	text-transform: uppercase;
	color: #000;
	letter-spacing: .5px;
}

.appsMenu.home .linksBlock .projectName {
	display: none;
}

.appsMenu.home .platform > .title {
	font-family: $fontSemiBold;
	border-radius: 30px;
	margin-top: 20px;
	margin-bottom: 10px;
	text-transform: uppercase;
}










.appsMenu.home .versions {
	display: flex;

}

.appsMenu.home .versionsItem + .versionsItem {
	margin-left: 50px;
}


.appsMenu.home .versionsItem > .title {
	display: inline-block;
	padding: 4px 15px;
    border-radius: 20px;
    text-transform: uppercase;
    font-size: 14px;
	font-family: $fontSemiBold;
	margin-bottom: 15px;
}

.appsMenu.home .versionsItem.dev > .title {
	background-color: #d2e3fc;
	color: #475e88;
}

.appsMenu.home .versionsItem.release > .title {
	background-color: #d2ffd3;
	color: #009804;
}






.appsMenu.home .socialName {
	display: none;
}

.appsMenu.home .socialTitle {
	margin-right: 10px;
}

.appsMenu.home a {
	text-decoration: none;
}

.appsMenu.home .link {
	width: 190px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 15px;
	padding: 5px 0;
}

.appsMenu.home .link.disabled .socialTitle {
	opacity: 0.3;
}

.appsMenu.home .titleName {
	width: 100%;
}

.appsMenu.home .link .feather {
	display: none;
} 

.appsMenu.home .appLink {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.appsMenu.home .socialLink {
	opacity: 0.6;
	font-size: 14px;
}

.appsMenu.home .socialLink::before {
	display: none;
}

.appsMenu.home .link:not(.disabled) .appLink:hover .socialTitle,
.appsMenu.home .socialLink:hover,
.appsMenu.home .mobile .link:hover {
	text-decoration: underline;
	cursor: pointer;
}




@media (max-width: 768px) {
	.appsMenu.home .item,
	.page-home .pageTitle {
		width: 100%;
		margin: 0;
		margin-bottom: 30px;
	}

	.appsMenu.home .versionsItem + .versionsItem {
		margin-left: 30px;
	}

	.appsMenu.home .link {
		width: 150px;
	}

	.appsMenu.home .socialLink .label {
		display: none;
	}

	.appsMenu.home .feather {
		position: relative;
		top: 7px;
		display: block;
		margin-left: 15px;
	}
}