@import	'vars';
@import './bootstrap-grid.css';
@import './UI/UI';

*, ::before, ::after {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	background-size: contain;
	outline: none;
}

html, body, #root {
	width: 100%;
	height: 100%;
}

body {
	font-family: $font, sans-serif;
	color: #2c2c2c;
}

b {
	font-family: $fontSemiBold;
}

a {
	color: #2c2c2c;
}

.center {
	text-align: center;
}

.container-fluid {
	max-width: 1800px
}













footer {
	margin: 0 50px;
	margin-top: 100px;
	padding: 17px 0;
	border-top: 1px solid #eee;
	font-size: 14px;
	text-align: center;
	color: rgba(0,0,0,.5);
}





.loadingSpinner {
	width: 50px;
	height: 50px;
}

.loadingSpinner.projectLoading {
	position: absolute;
	left: 50%;
	top: 50%;
	width: 100px;
	height: 100px;
	transform: translateX(-50%);
}

.loadingSpinner .circle {
	width: 100%;
	height: 100%;
	background-image: url(/img/spinner.png);
	animation: rotate360 1.5s linear infinite;
}

@keyframes rotate360 {
	0% {
		transform: rotate(0deg)
	}

	100% {
		transform: rotate(360deg)
	}
}







#deniedAccess {
	position: absolute;
    left: 0;
    top: 20%;
    right: 0;
    width: 540px;
    padding: 20px;
    margin: 0 auto;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 7px;
    text-align: center;
    font-size: 17px;
}

.hinted {
	display: inline-block;
	border-bottom: 2px dashed #ccc;
}

.hinted:hover {
	border-color: rgba(0,0,0,0)
}

@import "./header";
@import "./appForm/appForm";
@import "./form";
@import "./pages/pages";
@import "./popup";
@import "./table";
@import "./popupMessage";
@import "./fieldModelPopup";
@import "./controls";
@import "./extraLinks";
@import "./messages";
@import "./infoMessage/infoMessage";
@import "./airDataPicker";
@import "./media";