.page-refund .form {
	display: inline-block;
	min-width: 303px;
}

.page-refund .selectField .options {
	width: 100%;
}

.page-refund .table .amount {
	text-align: center;
}

.page-refund .table {
	max-height: 312px;
    overflow: auto;
    display: block;
	margin-top: 30px;
}

.page-refund .table .status {
	text-align: center;
}

.page-refund .refundBtn {
	width: 120px;
    padding: 6px 12px;
    padding-bottom: 8px;
	margin: -8px 0;
}

.page-refund .showBtn {
	height: 50px;
	margin-top: 20px;
}


.page-refund .loadingSpinner {
	margin: 0 auto;
	margin-top: 30px;
}

.page-refund .tableContainer .loadingSpinner {
	position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
	margin: 0;
}