.featuresPoints .infoMessage {
	margin: 0 80px;
	margin-bottom: 40px;
}

.featuresPoints .app .appName {
    font-size: 16px;
    text-transform: uppercase;
	font-family: $font;
    margin-bottom: 17px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	padding: 5px 12px;
	border-radius: 7px;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.featuresPoints .app {
	display: inline-block;
	margin: 0 80px;
	margin-bottom: 100px;
}

.featuresPoints .features {
	display: flex;
	flex-direction: column;
}

.featuresPoints .feature {
	position: relative;
	max-width: 260px;
	margin-bottom: 15px;
}

.featuresPoints .feature .flag {
	position: absolute;
	z-index: 1;
    left: -30px;
    top: 50%;
    transform: translateY(-50%);
}

.featuresPoints .feature .feather-flag {
	opacity: 0.6;
}

.featuresPoints .features .name {
	font-size: 14px;
	opacity: 0.6;
}

.featuresPoints .features .title {
	font-family: $fontSemiBold;
	font-size: 16px;
}

.featuresPoints .features .line {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.featuresPoints .features .point {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #eee;
	white-space: pre;
	font-size: 16px;
	padding: 6px 10px;
	border-radius: 7px;
	margin-left: 10px;
}






.featuresPoints .find .appName {
	background-color: #8d84e8;
}

.featuresPoints .cat .appName {
	background-color: #5da283;
}

.featuresPoints .trip .appName {
	background-color: #f8df72;
	color: #554607;
}

.featuresPoints .villa .appName {
	background-color: #fc979a;
}

.featuresPoints .alice .appName {
	background-color: #aecf55;
}

.featuresPoints .words .appName {
	background-color: #4ecbc4;
}

.featuresPoints .sweet .appName {
	background-color: #b36bd4;
}