.ant-modal.battlePassRewards .autoFillRewards {
	display: flex;
	align-items: center;
}

.ant-modal.battlePassRewards .labelCheck {
	margin-left: 7px;
}





.ant-modal.battlePassRewards .ant-table-thead {
	position: sticky;
	left: 0;
	top: 0;
	z-index: 1;
}

.ant-modal.battlePassRewards .ant-table {
	max-height: 390px;
    overflow: auto;
	margin-top: 10px;
}

.ant-modal.battlePassRewards .removeBtn {
	display: flex;
	align-items: center;
	opacity: 0;
}

.ant-modal.battlePassRewards .ant-table-row:hover .removeBtn {
	opacity: 1;
}






.ant-modal.battlePassRewards .ant-select {
	width: 143px;
}




.ant-modal.battlePassRewards .addBtn {
	width: 100%;
    height: 40px;
    margin: 10px 0;
}






.ant-modal.battlePassRewards .fillFilter {
	padding: 10px;
}