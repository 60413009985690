.snapshotsModal .snapshots {
	max-height: 360px;
    overflow: auto;
}

.snapshotsModal .snapshot {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 7px;
	line-height: 16px;
	border: 1px solid #ccc;
	margin: 10px 0;
	padding: 6px 14px;
}

.snapshotsModal .snapshot .btn {
	margin-left: 10px;
}

.snapshotsModal .newSnapshotForm {
	display: flex;
	align-items: center;
	margin-top: 30px;
}

.snapshotsModal .ant-form-item {
	flex: 1;
}

.snapshotsModal .addBtn {
	margin-left: 10px;
}

.snapshotsModal .buttons {
	display: flex;
	align-items: center;
}







@media (max-width: 768px) {
	.snapshotsModal .snapshot {
		flex-direction: column;
		align-items: flex-start;
	}

	.snapshotsModal .snapshot .btn {
		margin: 0;
		margin-top: 10px;
		margin-right: 10px;
	}
}