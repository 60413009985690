.project-page .ant-select-selector,
.project-page .ant-select-dropdown {
	font-size: 15px!important;
	font-family: $font!important;
}

.project-page .helpButtons {
	position: relative;
	z-index: 1;
	top: 20px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
    background-color: #f5f5f5;
    border-radius: 7px;
	padding: 11px 15px;
}

.project-page .helpButtons ::selection {
	background-color: rgba(0,0,0,0);
}

.project-page .helpButtons .button {
	display: inline-flex;
	font-size: 14px;
	padding: 8px 12px;
	margin-right: 15px;
}

.project-page .helpButtons .button .feather {
	opacity: 0.6;
	margin-right: 6px;
}


.project-page .helpButtons .group {
	position: relative;
	display: flex;
	align-items: center;
	margin-right: 20px;
	font-size: 14px;
}

.project-page .helpButtons .checkboxField {
	display: flex;
	align-items: center;
}

.project-page .helpButtons .checkboxField .labelCheck {
	margin-left: 10px;
	margin-right: 0;
}





.project-page .helpButtons .fillPlayerStars {
	cursor: pointer;
}





.project-page .helpButtons .exportBtn .feather,
.project-page .helpButtons .importBtn .feather {
	transform: rotate(-90deg);
	margin: -2px 0;
	margin-right: 5px;
}

.project-page .helpButtons .importBtn .feather {
	transform: rotate(90deg);
}