#navbarPopup {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 3;
	width: 100vw;
	height: 100vh;
	background-color: #256bd4;
	opacity: 0;
	transition: opacity .25s;
	padding: 30px;
	overflow: auto;
}

#navbarPopup.anim {
	opacity: 1;
}

#navbarPopup .closeBtn {
	position: fixed;
	right: 30px;
	top: 30px;
	width: 30px;
	height: 30px;
	cursor: pointer;
}

#navbarPopup .closeBtn::before,
#navbarPopup .closeBtn::after {
	content: '';
	position: absolute;
	top: 0;
	width: 2px;
	height: 41px;
	background-color: #fff;
}

#navbarPopup .closeBtn::before {
	left: 0;
	transform-origin: left top;
	transform: rotate(-45deg);
}

#navbarPopup .closeBtn::after {
	right: 0;
	transform-origin: right top;
	transform: rotate(45deg);
}






#navbarPopup .sectionTitle {
	font-size: 24px;
	font-family: $fontSemiBold;
	color: #fff;
	margin-bottom: 20px;
}

#header #navbarPopup .pagesLinks,
#navbarPopup .apps {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

#header #navbarPopup .pagesLinks .link {
	display: flex;
	align-items: center;
	font-size: 17px;
	color: #fff;
	margin: 5px 0;
}

#header #navbarPopup .pagesLinks .link:hover {
	text-decoration: underline;
}









#navbarPopup .apps .item {
	font-size: 17px;
	color: #fff;
	font-family: $font;
	margin-bottom: 50px;
}

#navbarPopup .linksBlock .projectName {
	display: none;
}

#navbarPopup .versionsItem .link:hover {
	background-color: transparent;
}

#navbarPopup .versionsItem .link a {
	color: #fff;
}

#navbarPopup .apps .projectTitle {
	display: none;
}

#navbarPopup .apps .projectName {
	font-family: $fontSemiBold;
	font-size: 24px;
    margin-bottom: 21px;
	text-transform: uppercase;
}

#navbarPopup .apps .socialLink {
	width: 22px;
    height: 22px;
	margin-left: 17px;
	opacity: 1;
}

#navbarPopup .apps .platform > .title,
#navbarPopup .apps .versionsItem .title {
	font-size: 21px;
}

#navbarPopup .apps .versionsItem .link {
	font-size: 19px;
}







#navbarPopup .apps .platform > .title {
	color: #fff;
}

#navbarPopup .platform + .platform {
	border-left: 1px solid rgba(255,255,255,.4);
}

#navbarPopup .ant-menu {
	border-radius: 7px;
}