.salesModal .form,
.page-infoPopups .form {
	width: 400px;
	margin: 0 auto;
}

.salesModal .ant-form-item-required::before,
.page-infoPopups .ant-form-item-required::before {
	display: none!important;
}

.salesModal .ant-form-item,
.page-infoPopups .ant-form-item {
	margin-bottom: 18px;
}

.salesModal .applyBtn,
.page-infoPopups .applyBtn {
	height: 45px
}

.salesModal .datesGroup,
.page-infoPopups .datesGroup {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
}

@media (max-width: 768px) {
	.salesModal .form,
	.page-infoPopups .form {
		width: 100%;
	}
}