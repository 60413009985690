@import "vars";

.fieldModelPopup {
	position: fixed;
    left: 0;
    top: 0;
	z-index: 3;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 13%);
}

.fieldModelPopup .content {
	position: absolute;
    left: 0;
    top: 0px;
    right: 0;
    bottom: 0;
    width: 400px;
    height: 400px;
    background-color: #fff;
    border-radius: 7px;
    margin: auto;
    padding: 28px;
}

.fieldModelPopup .fieldName {
	margin-bottom: 16px;
	font-size: 19px;
	font-family: $fontSemiBold;
}

.fieldModelPopup .fieldValue {
	font-size: 17px;
    height: 250px;
    display: block;
    overflow: auto;
    outline: none;
    border: 2px solid #dbdbdb;
    border-radius: 7px;
    padding: 9px;
	white-space: pre;
	width: 100%;
	resize: none;
}

.fieldModelPopup .saveBtn {
	position: absolute;
    left: 28px;
    bottom: 20px;
    right: 28px;
    height: 50px;
    border-radius: 7px
}