.villaActionsModal .controls {
	margin-top: 10px;
}

.villaActionsModal .controls {
	margin-right: 15px;
}

.villaActionsModal .partsNames {
	margin-top: 10px;
}