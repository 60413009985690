.ant-modal.interstitialAd .ant-radio-group {
	margin-bottom: 22px;
	margin-top: 5px;
}

.ant-modal.interstitialAd .iTitle {
	display: flex;
	align-items: center;
	font-size: 16px;
	font-family: $fontSemiBold;
	margin-bottom: 7px;
}

.ant-modal.interstitialAd .iTitle .name {
	font-family: $font;
	opacity: 0.6;
	margin-left: 10px;
	font-size: 14px;
}

.ant-modal.interstitialAd .info {
	font-size: 15px;
	line-height: 22px;
}

.ant-modal.interstitialAd .serverConfig {
	margin-top: 15px;
}

.ant-modal.interstitialAd .serverConfig .item {
	display: flex;
	align-items: center;
	font-size: 14px;
	font-family: $fontSemiBold;
}

.ant-modal.interstitialAd .serverConfig .item .name {
	margin: 3px 7px;
	opacity: 0.6;
	font-family: $font;
}