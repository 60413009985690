.button {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	padding: 16px 12px;
}

.button:not(.ant-btn) {
	color: #fff;
	cursor: pointer;
	padding: 7px 12px;
	border-radius: 7px;
	border: 1px solid rgba(0,0,0,0);
}

.button::selection {
	background-color: rgba(0,0,0,0);
}

.button.blue {
	background-color: #2072f2;
}

.button.blue:not(.wait):hover {
	background-color: #1d68dc;
}

.button.blue:active {
	background-color: #1851ab;
}



.button.grey {
	color: #757575;
    border: 1px solid #ccc;
	background-color: #fff;
}

.button.grey:not(.wait):hover {
	background-color: #f6f6f6;
}

.button.grey:active {
	background-color: #ccc;
}









.button.darkGrey {
	color: #1e1e1e;
    border: 1px solid #959595;
	background-color: #fff;
}

.button.darkGrey:not(.wait):hover {
	background-color: #f6f6f6;
}

.button.darkGrey:active {
	background-color: #ccc;
}




.button.disabled {
	opacity: 0.6;
	pointer-events: none;
}

.button .feather-rotate-cw {
	position: relative;
	left: 10px;
}

.button.wait {
	cursor: wait;
}

.button.wait .feather-rotate-cw {
	animation: rotate360 1.5s linear infinite;
}








.ant-btn {
	display: flex;
	align-items: center;
	justify-content: center;
}

.ant-btn-primary:disabled {
	cursor: not-allowed;
    border-color: transparent;
    color: #fff;
    background-color: #1677ff;
    box-shadow: none;
    opacity: .6;
}


.ant-popconfirm-buttons .ant-btn {
	display: inline-block;
}