#bottomPanel {
	position: fixed;
	z-index: 10;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	justify-content: center;
	align-items: center;
	transition: opacity .35s 1.5s;
}

#bottomPanel.disabled {
	opacity: 0.5;
}

#bottomPanel .buttons {
	display: flex;
	margin-bottom: 20px;
	background-color: rgba(131, 131, 131, 0.16);
    padding: 10px;
    border-radius: 10px;
}

#bottomPanel .button {
	display: inline-flex;
	padding: 0px 50px;
    height: 55px;
    border-radius: 7px;
    font-size: 16px;
	pointer-events: auto;
	white-space: pre;
	font-family: $font;
}

#bottomPanel.disabled .button {
	pointer-events: none;
}

#bottomPanel .resetBtn {
	background-color: #fff;
    border: 1px solid #ccc;
    margin-left: 30px;
    color: rgb(0 0 0 / 50%);
}

#bottomPanel .resetBtn:hover {
	color: #482f00;
    background-color: orange;
	border-color: rgba(0,0,0,0);
}

#bottomPanel .dontForget {
	border-radius: 30px;
    padding: 5px;
    text-align: center;
    margin-bottom: 7px;
	font-size: 14px;
}

#bottomPanel .saveBtn {
	width: 200px;
}

#bottomPanel .saveBtn .label {
	display: none;
}

#bottomPanel .saveBtn.changed .label {
    position: absolute;
    left: 50%;
    top: -32px;
    font-size: 13px;
    color: #e72b2b;
    text-align: center;
    display: block;
    background-color: #fff;
    transform: translateX(-50%);
}

#bottomPanel .saveBtn.changed::before {
	content: "";
    position: absolute;
    right: -7px;
    top: -8px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #ff3434;
    box-shadow: 0 0 10px -1px #ff3434;
}