@import "vars";

#loginForm {
	position: absolute;
    left: 50%;
    top: 20%;
    width: 310px;
    background-color: #fff;
    transform: translateX(-50%);
    border: 1px solid #eee;
    box-shadow: 0 0 20px -6px rgb(0 0 0 / 12%);
    padding: 27px 30px;
	border-radius: 7px;
}

#loginForm input {
	display: block;
    border: 1px solid #a9b7d5;
    border-radius: 7px;
    padding: 14px 12px;
    font-size: 19px;
    outline: none;
    width: 100%;
    margin-bottom: 25px;
	font-family: $font;
}

#loginForm input:focus {
	border-color: #2072f2;
}

#loginForm .loginBtn {
	display: inline-block;
	height: 52px;
	font-size: 16px;
	padding: 10px 30px;
	border-radius: 7px;
    width: 100%;
    text-align: center;
    padding: 12px 0;
    font-size: 17px;
}

#loginForm label {
	font-size: 15px;
    font-family: $fontSemiBold;
    margin-bottom: 7px;
    display: inline-block;
}

#loginForm .message {
	display: none;
	margin-top: 20px;
	color: #ed4545;
	font-size: 15px;
    font-family: $fontSemiBold;
}

#loginForm .message.show {
	display: block;
}